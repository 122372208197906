
import Vue from "vue";

import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import DocumentCategoryActivitiesTimelineItem from "./DocumentCategoryActivitiesTimelineItem.vue";
interface IDocumentCategoryActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "DocumentCategoryAllActivitiesView",
  components: { DocumentCategoryActivitiesTimelineItem },
  mixins: [ActivitiesMixin],

  data(): IDocumentCategoryActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `
          DELETE_DOCUMENT_CATEGORY_SUCCESS,
          DELETE_DOCUMENT_CATEGORY_FAILURE,
          CREATE_DOCUMENT_CATEGORY_SUCCESS,
          CREATE_DOCUMENT_CATEGORY_FAILURE,
          UPDATE_DOCUMENT_CATEGORY_SUCCESS,
          UPDATE_DOCUMENT_CATEGORY_FAILURE
          `.replace(/\s/g, ""),
        activityType: "DocumentCategoryActivity"
      };
    }
  }
});
